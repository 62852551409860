import React from "react";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import { NumericFormat } from "react-number-format";
import { Input, Tag, Space, Dropdown, Menu } from "antd";

import {
  IsdLookup,
  NeighborhoodLookup,
  ParentInterestLookup,
  ChildInterestLookup,
  ChildSpecialNeedsLookup,
  CookChildren,
  ConnectedBy,
} from "../../Lookups";
import Users from "../../../assets/images/users.svg";
import { RenderChildren } from "./AddNotification/Children";
import dateTimeService from "../../../util/date-time.service";
import {
  CONDITION_TYPE,
  CONNECTED_TYPE,
  REDIRECTION_TYPE,
  RULE_TYPE,
} from "../../../constants/constantTypes";
import {
  CONNECT_TYPE_LABEL,
  IN_APP_REDIRECTION_TABS,
  IN_APP_TAB_IDS,
} from "../../../constants/constant";

import Find from "../../../assets/images/FIND.svg";
import View from "../../../assets/images/view.svg";
import Chat from "../../../assets/images/Chat.svg";
import Setting from "../../../assets/images/Setting.svg";
import Access from "../../../assets/images/Access.svg";
import Connect from "../../../assets/images/connect.svg";
import Explore from "../../../assets/images/EXPLORE.svg";
import See_Freebies from "../../../assets/images/See_freebies.svg";
import Get from "../../../assets/images/Get.svg";
import Call from "../../../assets/images/CALL.svg";
import Navigator from "../../../assets/images/Navigator.svg";
import Education_Support from "../../../assets/images/go_beyond-grades.png";
import School_A_Hoop from "../../../assets/images/schoolHoop.png";
import Tootris from "../../../assets/images/Tootris.png";

const HOUR_FORMAT = "HH:mm:ss";
const { formatDateToSpecificTZ } = dateTimeService;
const betweenTypes = [CONDITION_TYPE.BETWEEN, CONDITION_TYPE.NOT_BETWEEN];

export const renderSelection = (index, form, getNotificationCount) => {
  const currentRule = form.getFieldValue(["rules", index, "selectedRule"]);
  const currentRuleType = currentRule?.type ?? currentRule?.ruleType;
  const notificationConditionId = form.getFieldValue([
    "rules",
    index,
    "notificationConditionId",
  ]);
  const obj = {
    index,
    form,
    getNotificationCount,
  };
  switch (currentRuleType) {
    case RULE_TYPE.NUMBER_OF_CHILDREN:
    case RULE_TYPE.AGE_OF_CHILDREN:
      return (
        <NumericFormat
          disabled={!Boolean(notificationConditionId)}
          allowNegative={false}
          className="ant-input"
          onValueChange={(values) => {
            return handleLookupChange({
              val: values?.value,
              ...obj,
            });
          }}
        />
      );
    case RULE_TYPE.NEIGHBOURHOOD:
      return (
        <NeighborhoodLookup
          onChange={(val) =>
            handleLookupChange({
              val,
              ...obj,
            })
          }
          disabled={!Boolean(notificationConditionId)}
          isCustomizeWidth={false}
          mode="multiple"
        />
      );
    case RULE_TYPE.ISD:
      return (
        <IsdLookup
          onChange={(val) =>
            handleLookupChange({
              val,
              ...obj,
            })
          }
          disabled={!Boolean(notificationConditionId)}
          mode="multiple"
        />
      );
    case RULE_TYPE.CONNECTED_TYPE:
      return (
        <ConnectedBy
          onChange={(val) => {
            handleLookupChange({
              val,
              ...obj,
            });
          }}
          disabled={!Boolean(notificationConditionId)}
          mode="multiple"
        />
      );
    case RULE_TYPE.PARENT_INTEREST:
      return (
        <ParentInterestLookup
          onChange={(val) =>
            handleLookupChange({
              val,
              ...obj,
            })
          }
          disabled={!Boolean(notificationConditionId)}
          mode="multiple"
        />
      );
    case RULE_TYPE.CHILD_INTEREST:
      return (
        <ChildInterestLookup
          onChange={(val) =>
            handleLookupChange({
              val,
              ...obj,
            })
          }
          disabled={!Boolean(notificationConditionId)}
          mode="multiple"
        />
      );
    case RULE_TYPE.SPECIAL_NEED:
      return (
        <ChildSpecialNeedsLookup
          onChange={(val) =>
            handleLookupChange({
              val,
              ...obj,
            })
          }
          disabled={!Boolean(notificationConditionId)}
          mode="multiple"
        />
      );
    case RULE_TYPE.COOK_CHILDREN:
      return (
        <CookChildren
          onChange={(val) => {
            handleLookupChange({
              val,
              ...obj,
            });
          }}
          disabled={!Boolean(notificationConditionId)}
          mode="multiple"
        />
      );
    default:
      return <Input disabled={true} />;
  }
};

export const handleLookupChange = (arg) => {
  const { val, index, form, getNotificationCount } = arg;
  const rules = form.getFieldValue(["rules"]) || [];

  if (!val?.length || !val) {
    rules[index].values = undefined;
  } else {
    rules[index].values = val;
  }
  form.setFieldsValue([
    {
      name: "rules",
      value: rules,
    },
  ]);
  debounce(() => getNotificationCount(modifyRules(rules)))();
};

export const handleValueChange = (arg) => {
  const { val, cIndex, form, getNotificationCount, pIndex, key } = arg;
  const rules = form.getFieldValue(["rules"]) || [];
  const isV1 = key === "value1";
  if (!val) {
    rules[pIndex].values[cIndex][key] = undefined;

    if (isV1) {
      rules[pIndex].values[cIndex]["value2"] = "";
    }
  } else {
    rules[pIndex].values[cIndex][key] = val;
  }
  form.setFieldsValue([
    {
      name: "rules",
      value: rules,
    },
  ]);
  if (
    val &&
    rules[pIndex].values[cIndex][key] &&
    rules[pIndex].values[cIndex][isV1 ? "value2" : "value1"]
  )
    debounce(() => getNotificationCount(modifyRules(rules)))();
};

export const getSelectedRuleIds = (form) => {
  const rules = form.getFieldValue(["rules"]) || [];
  let selectedIds = [];
  rules.forEach((info) => {
    if (info?.notificationRuleId) {
      selectedIds.push(info?.notificationRuleId);
    }
  });
  return selectedIds;
};

// export const handleRule = (arg) => {
//     const { value, selectedOption, index, form } = arg;
//     const rules = form.getFieldValue(["rules"]) || [];
//     if (!value) {
//         rules[index].notificationRuleId = undefined;
//         rules[index].ruleType = undefined;
//         rules[index].selectedRule = undefined;
//         rules[index].notificationConditionId = undefined;
//         rules[index].values = undefined;
//     } else {
//         rules[index].notificationRuleId = value;
//         rules[index].ruleType = selectedOption?.type;
//         rules[index].selectedRule = selectedOption;
//     }
//     form.setFieldsValue([
//         {
//             name: "rules",
//             value: rules
//         }
//     ]);

// };
export const handleRule = (arg) => {
  const { value, selectedOption, index, form } = arg;
  const rules = form.getFieldValue(["rules"]) || [];
  rules[index].notificationRuleId = undefined;
  rules[index].ruleType = undefined;
  rules[index].selectedRule = undefined;
  rules[index].notificationConditionId = undefined;
  rules[index].values = undefined;
  rules[index].conditionType = undefined;
  if (value) {
    rules[index].notificationRuleId = value;
    rules[index].ruleType = selectedOption?.type;
    rules[index].selectedRule = selectedOption;
  }
  form.setFieldsValue([
    {
      name: "rules",
      value: rules,
    },
    {
      name: "values",
      value: undefined,
    },
    {
      name: "notificationRuleId",
      value: undefined,
    },
  ]);
};

export const handleConditionChange = ({ val, selectedOption, index, form }) => {
  const rules = form.getFieldValue(["rules"]);
  if (!val) {
    rules[index].notificationConditionId = undefined;
    rules[index].conditionType = undefined;
    rules[index].values = undefined;
  } else {
    rules[index].notificationConditionId = val;
    rules[index].conditionType = selectedOption?.id;
    rules[index].values = betweenTypes.includes(selectedOption?.id)
      ? [{}]
      : undefined;
  }
  form.setFieldsValue([
    {
      name: "rules",
      value: rules,
    },
  ]);
};

export const modifyRules = (rules = []) => {
  const modifiedRules = rules.map((info) => {
    const { selectedRule, ...rest } = info;
    if (
      info?.ruleType === RULE_TYPE.AGE_OF_CHILDREN ||
      info?.ruleType === RULE_TYPE.NUMBER_OF_CHILDREN
    ) {
      const updatedValues = {
        ...rest,
      };
      return {
        ...rest,
        values: !betweenTypes.includes(info?.conditionType)
          ? info?.values
            ? [Number(info?.values)]
            : []
          : getBetweenPayload(info?.values),
      };
    } else {
      if (rest.values.indexOf(CONNECTED_TYPE.EMAIL) === -1) return rest;
      return {
        ...rest,
        values: [...rest.values, CONNECTED_TYPE.PHONE, CONNECTED_TYPE.USER],
      };
    }
  });
  return modifiedRules;
};

export const renderAction = (record, onActionClick) => {
  if (!record?.actions || record?.actions?.length === 0) {
    return;
  } else if (record.actions.length) {
    return (
      <div className="gx-d-flex gx-justify-content-center">
        <Dropdown
          overlay={
            <Menu>
              {record?.actions?.map((key, index) => {
                return <Menu.Item>View Content</Menu.Item>;
              })}
            </Menu>
          }
          placement="bottomRight"
        >
          <div className="circle-icon" align="center">
            <MoreOutlined className="circle-icon-content" />
          </div>
        </Dropdown>
      </div>
    );
  }
};

const getBetweenPayload = (values = []) => {
  const result = [];
  values.forEach((info) => {
    const isVal2Greater = info?.value2 > info?.value1;
    if (isVal2Greater && info?.value2 && info?.value1) {
      result.push(Number(info?.value1), Number(info?.value2));
    } else {
      if (info?.value2 && info?.value1) {
        result.push(Number(info?.value2), Number(info?.value1));
      }
    }
  });
  return result;
};

export function onDetailSuccess(details, form, getNotificationCount) {
  let obj = form.getFieldsValue();
  obj["name"] = details?.name;
  obj["nameSpanish"] = details?.nameSpanish;
  obj["linkEnglish"] = details?.linkEnglish ?? "";
  obj["linkSpanish"] = details?.linkSpanish ?? "";
  obj["isConditional"] = details?.isConditional ? 1 : 2;
  obj["date"] = moment(details?.sendDate);
  obj["time"] = moment(
    formatDateToSpecificTZ(details?.sendDate, HOUR_FORMAT),
    HOUR_FORMAT
  );
  obj["message"] = details?.message;
  obj["messageSpanish"] = details?.messageSpanish;

  if (
    [
      REDIRECTION_TYPE.ACCESS,
      REDIRECTION_TYPE.ACTIVITY,
      REDIRECTION_TYPE.CALL,
      REDIRECTION_TYPE.COOK_CHILDREN_HEALTH_PLAN,
      REDIRECTION_TYPE.CHAT,
      REDIRECTION_TYPE.EXPLORE,
      REDIRECTION_TYPE.FAMILY_NAVIGATOR,
      REDIRECTION_TYPE.FIND,
      REDIRECTION_TYPE.FREEBIE,
      REDIRECTION_TYPE.GET,
      REDIRECTION_TYPE.RECOMMEND_CONNECT,
      REDIRECTION_TYPE.SETTING,
    ]?.includes(details?.redirectionType)
  ) {
    obj["isInAppRedirection"] = true;
    obj["title"] = OtherTabData[details?.redirectionType]["name"];
    obj["type"] = "Other";
    obj["redirectionType"] = details?.redirectionType;
  }
  if (
    [
      REDIRECTION_TYPE.EDUCATION_SUPPORT,
      REDIRECTION_TYPE.TOOTRIS,
      REDIRECTION_TYPE.SCHOOL_A_HOOP,
    ]?.includes(details?.redirectionType)
  ) {
    obj["isInAppRedirection"] = true;
    obj["title"] = FindTabData[details?.redirectionType]["name"];
    obj["type"] = "Find";
    obj["redirectionType"] = details?.redirectionType;
  }

  if (details?.activity) {
    obj["isInAppRedirection"] = true;
    obj["title"] = details?.activity?.title;
    obj["type"] = IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.EVENT]["name"];
    obj["activityDayId"] = details?.activity?.activityDayId;
  }
  if (details?.freebie) {
    obj["isInAppRedirection"] = true;
    obj["title"] = details?.freebie?.title;
    obj["type"] = IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.FREEBIE]["name"];
    obj["freebieId"] = details?.freebie?.id;
  }

  if (details?.post) {
    obj["isInAppRedirection"] = true;
    obj["title"] = details?.post?.title;
    obj["type"] = IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.CHAT]["name"];
    obj["postId"] = details?.post?.id;
  }

  if (details?.explore) {
    obj["isInAppRedirection"] = true;
    obj["title"] = details?.explore?.name;
    obj["type"] = IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.EXPLORE]["name"];
    obj["exploreId"] = details?.explore?.id;
  }

  if (details?.rules?.length) {
    obj["rules"] = details?.rules?.map((info, index) => {
      const updatedInfo = {
        ...info,
        selectedRule: info,
      };
      if (
        info?.ruleType === RULE_TYPE.AGE_OF_CHILDREN ||
        info?.ruleType === RULE_TYPE.NUMBER_OF_CHILDREN
      ) {
        if (
          info?.conditionType == CONDITION_TYPE.BETWEEN ||
          info?.conditionType == CONDITION_TYPE.NOT_BETWEEN
        ) {
          updatedInfo["values"] = mappingValues(info?.values, index);
        } else {
          updatedInfo["values"] = info?.values[0];
        }
      }
      if (info?.values?.indexOf(CONNECTED_TYPE.EMAIL) === -1)
        return updatedInfo;

      return {
        ...updatedInfo,
        values: info?.values?.filter(
          (rule) =>
            rule !== CONNECTED_TYPE.PHONE && rule !== CONNECTED_TYPE.USER
        ),
      };
    });
    getNotificationCount(modifyRules(obj["rules"]));
  } else {
    obj["rules"] = [{}];
  }

  form.setFieldsValue(obj);
}

const mappingValues = (values = [], pIndex) => {
  const result = [];
  for (let i = 0; i < values.length; i += 2) {
    result.push({
      value1: values[i],
      value2: values[i + 1],
    });
  }
  return result;
};

let timer;
export function debounce(func, timeout = 1500) {
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export const _MAP_RULE_DESCRIPTION = (type, info, values) => {
  switch (type) {
    case RULE_TYPE.NUMBER_OF_CHILDREN:
    case RULE_TYPE.AGE_OF_CHILDREN:
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <Space size="small">
            <div className="icon20">
              <img src={Users} />
            </div>
            <p className="text-color-body gx-m-0 noirProRegular gx-font-weight-medium gx-fs-md">
              {!betweenTypes.includes(info?.conditionType)
                ? `${capitalizeFirstLetter(info?.conditionName)} ${
                    info?.values[0]
                  }`
                : info?.values?.map((val, i) => {
                    if (i % 2 !== 0) {
                      return "";
                    }
                    return (
                      <span className="gx-mr-1">{`${val} - ${
                        info?.values[i + 1]
                      }${i !== info?.values?.length - 2 ? "," : ""}`}</span>
                    );
                  })}
            </p>
          </Space>
        </div>
      );

    case RULE_TYPE.CONNECTED_TYPE:
      const filteredValue = values?.filter(
        (rule) => rule !== CONNECTED_TYPE.PHONE && rule !== CONNECTED_TYPE.USER
      );
      return (
        <p className=" description-text  link-text  gx-m-0 noirProRegular gx-font-weight-medium ">
          {filteredValue?.map((val, i) => {
            return (
              <Tag
                color="#97c662"
                className={`active-tag-custom gx-m-0 gx-mb-3 ${
                  i !== filteredValue?.length - 1 && "gx-mr-2"
                }`}
                key={i}
              >
                {CONNECT_TYPE_LABEL[val]}
              </Tag>
            );
          })}
        </p>
      );
    default:
      return (
        <p className=" description-text  link-text  gx-m-0 noirProRegular gx-font-weight-medium ">
          {info?.entityNames?.map((val, i) => {
            return (
              <Tag
                color="#97c662"
                className={`active-tag-custom gx-m-0 gx-mb-3 ${
                  i !== info?.entityNames?.length - 1 && "gx-mr-2"
                }`}
              >
                {val}
              </Tag>
            );
          })}
        </p>
      );
  }
};

export const keyInfo = (currKey) => {
  if (currKey == IN_APP_TAB_IDS.EVENT) {
    return "SEARCH_ACTIVITIES";
  } else if (currKey == IN_APP_TAB_IDS.FREEBIE) {
    return "SEARCH_FREEBIE";
  } else if (currKey == IN_APP_TAB_IDS.CHAT) {
    return "SEARCH_CHAT";
  } else if (currKey == IN_APP_TAB_IDS.EXPLORE) {
    return "SEARCH_EXPLORE";
  } else if (currKey == IN_APP_TAB_IDS.ACCESS) {
    return "SEARCH_ACCESS";
  }
};

export const getSearchApiMethod = (currKey) => {
  if (currKey == IN_APP_TAB_IDS.EVENT) {
    return "getSearchActivities";
  } else if (currKey == IN_APP_TAB_IDS.FREEBIE) {
    return "getSearchFreebie";
  } else if (currKey == IN_APP_TAB_IDS.CHAT) {
    return "getSearchPosts";
  } else if (currKey == IN_APP_TAB_IDS.EXPLORE) {
    return "getSearchExplore";
  } else if (currKey == IN_APP_TAB_IDS.ACCESS) {
    return "getSearchAccess";
  }
};

export const titleInfo = (currKey, selectedData) => {
  if (currKey == IN_APP_TAB_IDS.EVENT) {
    return selectedData?.title;
  } else if (currKey == IN_APP_TAB_IDS.FREEBIE) {
    return selectedData?.content?.headline;
  } else if (currKey == IN_APP_TAB_IDS.CHAT) {
    return selectedData?.content?.headline;
  } else if (
    currKey == IN_APP_TAB_IDS.EXPLORE ||
    currKey == IN_APP_TAB_IDS.ACCESS ||
    currKey == IN_APP_TAB_IDS.OTHER
  ) {
    return selectedData?.name ?? selectedData?.title;
  } else {
    return selectedData?.name;
  }
};

export const typeInfo = (currKey) => {
  if (currKey == IN_APP_TAB_IDS.EVENT) {
    return IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.EVENT]["name"];
  } else if (currKey == IN_APP_TAB_IDS.FREEBIE) {
    return IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.FREEBIE]["name"];
  } else if (currKey == IN_APP_TAB_IDS.CHAT) {
    return IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.CHAT]["name"];
  } else if (currKey == IN_APP_TAB_IDS.EXPLORE) {
    return IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.EXPLORE]["name"];
  } else if (currKey == IN_APP_TAB_IDS.FIND) {
    return IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.FIND]["name"];
  } else if (currKey == IN_APP_TAB_IDS.ACCESS) {
    return IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.ACCESS]["name"];
  } else if (currKey == IN_APP_TAB_IDS.OTHER) {
    return IN_APP_REDIRECTION_TABS[IN_APP_TAB_IDS.OTHER]["name"];
  }
};

const mappingValue = (values = []) => {
  const result = [];
  for (let i = 0; i < values.length; i += 2) {
    result.push({
      value1: values[i],
      value2: values[i + 1],
    });
  }
  return result;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const OtherTabData = {
  [REDIRECTION_TYPE.FIND]: {
    redirectionType: REDIRECTION_TYPE.FIND,
    name: "Find",
    icon: Find,
    description: "Child Care and Education",
  },
  [REDIRECTION_TYPE.RECOMMEND_CONNECT]: {
    redirectionType: REDIRECTION_TYPE.RECOMMEND_CONNECT,
    name: "Recommend & Connect",
    icon: Connect,
    description: "with Community Resources",
  },
  // [REDIRECTION_TYPE.GET]: {
  //   redirectionType: REDIRECTION_TYPE.GET,
  //   name: "Get",
  //   icon: Get,
  //   description: "Crime Data in My Neighborhood",
  // },
  // [REDIRECTION_TYPE.CALL]: {
  //   redirectionType: REDIRECTION_TYPE.CALL,
  //   name: "Call",
  //   icon: Call,
  //   description: "Emergency Hotlines",
  // },
  [REDIRECTION_TYPE.FAMILY_NAVIGATOR]: {
    redirectionType: REDIRECTION_TYPE.FAMILY_NAVIGATOR,
    name: "Family Navigator",
    icon: Navigator,
    description: "Family and Child Specialists",
  },

  [REDIRECTION_TYPE.FREEBIE]: {
    redirectionType: REDIRECTION_TYPE.FREEBIE,
    name: "Freebie",
    icon: See_Freebies,
    description: "Free Stuff from Neighbors",
  },
  [REDIRECTION_TYPE.ACTIVITY]: {
    redirectionType: REDIRECTION_TYPE.ACTIVITY,
    name: "Event",
    icon: View,
    description: "Neighborhood Events Calender",
  },
  [REDIRECTION_TYPE.ACCESS]: {
    redirectionType: REDIRECTION_TYPE.ACCESS,
    name: "Access",
    icon: Access,
    description: "Parent Reading Material",
  },
  [REDIRECTION_TYPE.EXPLORE]: {
    redirectionType: REDIRECTION_TYPE.EXPLORE,
    name: "Explore",
    icon: Explore,
    description: "Child Learning and At-Home Activities",
  },
  [REDIRECTION_TYPE.CHAT]: {
    redirectionType: REDIRECTION_TYPE.CHAT,
    name: "Chat",
    icon: Chat,
    description: "with Local Parents",
  },
  [REDIRECTION_TYPE.SETTING]: {
    redirectionType: REDIRECTION_TYPE.SETTING,
    name: "Setting",
    icon: Setting,
    description: "Manage App preference",
  },
};

export const FindTabData = {
  [REDIRECTION_TYPE.EDUCATION_SUPPORT]: {
    redirectionType: REDIRECTION_TYPE.EDUCATION_SUPPORT,
    name: "Education Support",
    icon: Education_Support,
    description: "Go Beyond Grades: Education Support",
  },
  [REDIRECTION_TYPE.TOOTRIS]: {
    redirectionType: REDIRECTION_TYPE.TOOTRIS,
    name: "Tootris",
    icon: Tootris,
    description: "Find Child Care, After School & Camps",
  },
  [REDIRECTION_TYPE.SCHOOL_A_HOOP]: {
    redirectionType: REDIRECTION_TYPE.SCHOOL_A_HOOP,
    name: "School A Hoop",
    icon: School_A_Hoop,
    description: "Find a School",
  },
};
